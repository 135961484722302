import authService from "@/services/auth.service";
import { createRouter, createWebHistory } from "vue-router";

async function getSpecificRegistrationJwt(to) {
  const queryKeys = Object.keys(to.query);
  // The following is required to avoid an endless loop
  if (queryKeys.length === 0 || !queryKeys.includes('auth_req_jwt')) {
    const path = to.path.split('&').join('/').split('?').join('/').split('/') // Split with several delimiters
    if (path.includes('dema1n') && path.includes('jeune')) {
      const jwt = await authService.generateSpecificRegistrationJwt('dema1n', 'jeune')
      return { path: to.path, query: { ...to.query, auth_req_jwt: jwt } }
    }
    if (path.includes('dema1n') && path.includes('benevole')) {
      const jwt = await authService.generateSpecificRegistrationJwt('dema1n', 'benevole')
      return { path: to.path, query: { ...to.query, auth_req_jwt: jwt } }
    }
    if (path.includes('inspire') && path.includes('lyceen')) {
      const jwt = await authService.generateSpecificRegistrationJwt('inspire', 'lyceen')
      return { path: to.path, query: { ...to.query, auth_req_jwt: jwt } }
    }
    if (path.includes('jobready')) {
      const jwt = await authService.generateSpecificRegistrationJwt('jobready')
      return { path: to.path, query: { ...to.query, auth_req_jwt: jwt } }
    }
  }
}

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/LoginLayout.vue"),
    children: [
      {
        path: "orientation",
        name: "orientation",
        component: () => import("@/pages/login/OrientationPage.vue"),
      },
      {
        path: "connexion",
        name: "connexion",
        component: () => import("@/pages/login/LoginPage.vue"),
      },
      {
        path: "deconnexion",
        name: "deconnexion",
        component: () => import("@/pages/login/LogoutPage.vue")
      },
      {
        path: "inscription",
        name: "inscription",
        component: () => import("@/pages/login/RegistrationPage.vue"),
      },
      {
        path: "mot-de-passe",
        name: "mot-de-passe",
        component: () => import("@/pages/login/ForgottenPasswordPage.vue"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: () => import("@/pages/login/ResetPasswordPage.vue"),
      },
      {
        path: "mot-de-passe-reponse",
        name: "mot-de-passe-reponse",
        component: () => import("@/pages/login/ForgottenPasswordResponsePage.vue"),
      },
      {
        path: 'specific-registration/dema1n/jeune',
        name: 'specific-registration-dema1n-jeune',
        component: () => import('@/pages/specific-registrations/SpeRegDema1nJeunePage.vue'),
        beforeEnter: [getSpecificRegistrationJwt],
      },
      {
        path: 'specific-registration/dema1n/jeune/stage',
        name: 'specific-registration-dema1n-jeune-stage',
        component: () => import('@/pages/specific-registrations/SpeRegDema1nJeunePage.vue'),
        beforeEnter: [getSpecificRegistrationJwt],
      },

      {
        path: 'specific-registration/dema1n/jeune/alternance',
        name: 'specific-registration-dema1n-jeune-alternance',
        component: () => import('@/pages/specific-registrations/SpeRegDema1nJeunePage.vue'),
        beforeEnter: [getSpecificRegistrationJwt],
      },
      {
        path: 'specific-registration/dema1n/benevole',
        name: 'specific-registration-dema1n-benevole',
        component: () => import('@/pages/specific-registrations/SpeRegDema1nBenevolePage.vue'),
        beforeEnter: [getSpecificRegistrationJwt]
      },
      {
        path: 'specific-registration/inspire/lyceen',
        name: 'specific-registration-inspire-lyceen',
        component: () => import('@/pages/specific-registrations/SpeRegInspireLyceenPage.vue'),
        beforeEnter: [getSpecificRegistrationJwt]
      },
      {
        path: 'specific-registration/inspire/obendy',
        name: 'specific-registration-inspire-obendy',
        component: () => import('@/pages/specific-registrations/SpeRegInspireObendyPage.vue'),
      }
    ],
  },
  {
    path: '/user',
    component: () => import('@/layouts/UserLayout.vue'),
    children: [
      {
        path: 'modifier-identifiant',
        name: 'user-email-modification',
        component: () => import('@/pages/user/update/ModifyEmailPage.vue'),
      },
      {
        path: 'attente-confirmation',
        name: 'user-email-modification-waiting-confirm',
        component: () => import('@/pages/user/update/WaitingConfirmPage.vue')
      },
      {
        path: 'confirmation-identifiant',
        name: 'user-email-confirm-modification',
        component: () => import('@/pages/user/update/ConfirmPage.vue')
      }
    ]
  },
  {
    path: "/global",
    component: () => import("@/layouts/GlobalLayout.vue"),
    children: [
      {
        path: "error/:type",
        component: () => import("@/pages/global/ErrorPage.vue"),
      },
      {
        path: "response/:type",
        component: () => import("@/pages/global/ResponsePage.vue"),
      },
    ],
  },
  {
    path: "/legals",
    component: () => import("@/layouts/LegalsLayout.vue"),
    children: [
      {
        path: "politique-confidentialite",
        component: () => import("@/pages/legals/PrivacyPolicyPage.vue")
      },
      {
        path: "conditions-generales",
        component: () => import("@/pages/legals/TermsOfServicePage.vue")
      },
      {
        path: "mention-legales",
        component: () => import("@/pages/legals/LegalMentionPage.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => ({ top: 0, left: 0 }),
});

export default router;
